import useIntersection from '@app/hooks/useIntersection';
// import { useScrollDirection } from '@app/hooks/useScrollDirection';
import React, { useEffect, useRef, useState } from 'react';
// import RevealFade from 'react-reveal/Fade';
// import {Fade as RevealFade} from 'react-awesome-reveal'

export const Fade = ({children, className, as = 'div', direction = 'Up', delay = 0, ...props}: any) => {
  const ref = useRef();
  const innerRef = useRef();
  const [show, setShow] = useState(false);
  const [visibility,scrollDirection] = useIntersection(innerRef, "0px");
  // const scrollDirection = useScrollDirection()
  // console.log('visibility', visibility, direction)
  const animate = direction ? `fadeIn${direction}` : 'fadeIn'
  useEffect(() => {
    setTimeout(() => {
      if (show) {
        if (!visibility && scrollDirection === 'up-enter') {
          setShow(false);
        }
      } else {
        setShow(!!visibility)
      }
      // if (visibility && direction === 'up-enter') {
      //   setShow(!!visibility)
      // } else {
      //   setShow(!!visibility)
      // }
    }, delay)
  }, [visibility])
  // if (!visibility) return (null)
  return React.createElement(as, {
    ref: innerRef,
    className: `animated animatedFadeInUp${show ?' ' + animate:''}${className ? ' ' + className :''}`,
    ...props,
  }, children)
}