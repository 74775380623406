import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '@app/components/layout'
import { Section } from '@app/components/Section'
import { CloseIcon } from '@app/components/icons/Close'
import { Fade } from "@app/components/animations/Fade";
import projectBoard from './images/projectboard_active.jpg';
import woman from './images/intro/woman.png';
import RS_SplashScreenPhoneMask from './images/splash-screen/RS_SplashScreenPhoneMask.png';
import title4 from './images/data-video/title4.png';
import time3 from './images/data-video/time3.png';
import phone5 from './images/data-video/phone5.png';
import name2 from './images/data-video/name2.png';
import track1 from './images/data-video/track1.png';
import endless from './images/endless.jpg';
import runerWithOutAPast from './images/stories/runner-without-a-past.png';
import runerBody from './images/stories/runners-body.png';
import theExtraction from './images/stories/the-extraction.png';
import alllogos from './images/alllogos.jpg';
import social from './images/social.jpg';
import app_store from './images/app_store.png'
import play_store_active from './images/play_store_active.png'
import './_all.scss';
// import Player from '@vimeo/player';


const BlogPost = ({ data }: any) => {
  const image = getImage(data.mdx.frontmatter.feature_image)
	const playerRef = React.useRef(null)
	const [playing, setPlaying] = React.useState(false)
	// React.useEffect(() => {
	// 	window && window.scroll((e) => {
	// 		const items: any = document.getElementsByClassName('.hideme');

	// 		for(let i = 0; i < items.length; i++) {
	// 			const item = items.item(i);
	// 			var bottom_of_object = item?.offsetTop + $(this).outerHeight();
	// 			var bottom_of_window = $(window).scrollTop() + $(window).height();
	// 			if( bottom_of_window > bottom_of_object ){
	// 				$(this).addClass('showme');
	// 			}
	// 			if( bottom_of_window < bottom_of_object ){
	// 				$(this).removeClass('showme');
	// 			}
	// 		}
	// 	});
	// }, [])

	const handlePlayVideo = () => {
		setPlaying(!playing);
		if (playing) {
			playerRef.current.pause();
		} else {
			playerRef.current.play();
		}
	}

	const init = () => {
		if ((typeof window !== 'undefined') && !playerRef.current) {
			import('@vimeo/player').then(res => {
				const Player = res.default
				const iframe = document.getElementById('mainVideo');
				playerRef.current = new Player(iframe);
			})
		}
	}

	React.useEffect(() => {
		init()
	}, [])
  return (
		<>
		<header>
			<nav className=" sticky">
				<ul className="nav-menu container-sm mb-0">
					<li className="nav-item">
						<Link className="" to="/" style={{ color: 'white' }}>homepage</Link>
					</li>
				</ul>
			</nav>
		</header>
    <div id='running-stories'>
      <div className="panel projectboard">
				<img src={projectBoard} alt=""/>
			</div>

			<div className="panel mainvideo videoEmbedContainer nomargin">
				<iframe id="mainVideo" className="video mainvideo" src="https://player.vimeo.com/video/509616614" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen playsInline></iframe>
				<div className="playOverlay_tint" onClick={handlePlayVideo}>

				</div>
				{playing ? null : <div id="playOverlay">
					<div className="playcopy central">Case Study</div>
					<div id="playButtonWrapper">
						<div id="playButton" onClick={handlePlayVideo}></div>
					</div>
				</div>}
				
			</div>

			<div className="header videoEmbedContainer intro">	
				<div className="intro-wrapper">
					<iframe id="introVideo" className="video headervideo" src="https://player.vimeo.com/video/449615167?muted=1&background=1&autoplay=1&autopause=0" frameBorder="0" allow="autoplay" loop={1} autoPlay={1} autopause={0}></iframe>
					<img src={woman} className="woman" alt="woman"/>
					<div className="centered-overlay">
						<div className="text-overlay">
							<h2 className="header-title">RUNNING <span></span> STORIES</h2>
						</div>

					</div>
					
				</div>	
			
				<div id="overlay" className="overlay video">
					<h1>An audio-tainment platform that turns running routes<br/>into immersive experiences using live data.</h1>
					<div className="down" id="down"></div>
				</div>

			</div>

			<div className="panel" id="intro">

				<div className="data-video">
					<div className="videoEmbedContainer splash overlay-asset">
						<iframe id="logo" className="video logo" src="https://player.vimeo.com/video/449124202?muted=1&background=1&autoplay=1&autopause=0" frameBorder="0" allow="autoplay" loop={1} autoPlay={1} autopause={0}></iframe>
					</div>				
					<img src={RS_SplashScreenPhoneMask} className="mask splash-screen" alt="Phone Mask"/>
				</div>
				
				<div className="text-overlay data-text">
					<Fade as="h2" direction={null}>
						Running tracker<br/>meets storytelling
					</Fade>
					<Fade as="p" direction={null}>
						Using real-time data, Running Stories is the world's first app that guides, monitors and entertains runners, by turning your running route into the stage for a gripping story. 
					</Fade>
				</div>
			</div>

			

			<div className="panel videoEmbedContainer centered-overlay nomargin">			
				<iframe id="wave" className="video wave" src="https://player.vimeo.com/video/449151846?muted=1&background=1&autoplay=1&autopause=0" frameBorder="0" allow="autoplay" loop={1} autoPlay={1} autopause={0}></iframe>
				<div className="text-overlay">
					<Fade as="h2" direction={null}>
						What you hear<br/>is what you see
					</Fade>				
				</div>
			</div>


			<div className="panel">
				
				<div className="data-video">
					<img src={phone5} alt="Phone"/>
					<img src={title4} className="overlay-asset title" alt="Title 4"/>
					<img src={time3} className="overlay-asset " alt="Time 3"/>
					<img src={name2} className="overlay-asset name " alt="Name 2"/>
					<img src={track1} className="overlay-asset track " alt="Track 1"/>
				</div>
				
				<div className="text-overlay data-text">
					<Fade as="h2" direction={null}>
						BASED ON<br/>REAL-TIME DATA 
					</Fade>
					<Fade as="p" direction={null}>
						Augmented Audio enhances your surroundings by sourcing data from open APIs to create a story designed to enter-train you. The app uses location data, weather, your running speed and more, making you the main character in the&nbsp;story.
					</Fade>
				</div>
				
			</div>

			<div className="panel videoEmbedContainer runner">
				<iframe id="runnerVideo" className="video runnervideo" src="https://player.vimeo.com/video/447062759?muted=1&background=1&autoplay=1&autopause=0" frameBorder="0" allow="autoplay" loop={1} autoPlay={1} autopause={0}></iframe>
			</div>
		
			<div className="panel run-plot">
				<div className="data-video">
					<div className="videoEmbedContainer plot">
						<iframe id="plotVideo" className="video plot" src="https://player.vimeo.com/video/449207260?muted=1&background=1&autoplay=1&autopause=0" frameBorder="0" allow="autoplay" loop={1} autoPlay={1} autopause={0}></iframe>
					</div>				
				</div>
				
				<div className="text-overlay data-text">
					<Fade as="h2" direction={null}>
						PLOT YOUR RUN.<br/>RUN A PLOT.
					</Fade>
					<Fade as="p" direction={null}>
						Using the app is simple. Pop on your sneakers, pick a story, go to the starting point on the map, and run.  
					</Fade>
				</div>
			</div>

			<div className="panel quotes-section">
			
				<div className="quotes">
					
					<div className="quote">
						<h2>"A clever way to take the boredom out&nbsp;of&nbsp;running."</h2>
						<h3>BBC</h3>
					</div>
					
				</div>
			</div>

			<div className="panel centered-overlay endless">
				<img src={endless} alt="Endless"/>
				<div className="text-overlay">
					<Fade as="h2" direction={null}>
						Endless stories<br/>in any genre
					</Fade>
					<Fade as="p" direction={null}>
						Whether it's a thriller, a comedy or a sci-fi story, you'll find it&nbsp;in&nbsp;our&nbsp;library.<br/>Here's a sneak peek of our first episodic stories. 
					</Fade>
				</div>
			</div>

			<div className="panel quotes-section">
			
				<div className="quotes">
					
					<div className="quote">
						<h2>"It puts you in the shoes of a story's hero to make your jogs fun."</h2>
						<h3>MASHABLE</h3>
					</div>
					
				</div>
			</div>

			<div className="panel stories">
				<div className="story runner-without-a-past" href="https://player.vimeo.com/video/449980327?autoplay=1" data-featherlight="iframe" data-featherlight-iframe-frameBorder="0" data-featherlight-iframe-allow="autoplay; encrypted-media" data-featherlight-iframe-allowFullScreen="true" data-featherlight-iframe-style="display:block;border:none;height:100vh;width:100vw;">
				
					<a className="pov_btn">Watch POV demo</a>
					<div className="story-inner">
						
						<img src={runerWithOutAPast} className="story-title" alt="Runner without a past"/>
						<ul>
							<li>Medium pace</li>
							<li>Conspiracy Fanatics</li>
						</ul>
						<p>With no recollection of himself, a man is on the run to recover his memories taking him down a path that unravels a dark and mysterious past.</p>
						<ul className="tags">
							<li>Thriller</li>
							<li>Episode 1 of 3</li>
							<li>5.5km</li>
						</ul>
					</div>
				</div>

				<div className="story runners-body">
					<div className="story-inner">
						<img src={runerBody} className="story-title" alt="Runners Body"/>
						<ul>
							<li>Jogs</li>
							<li>Sitcom addicts</li>
						</ul>
						<p>It's the world's first audio sitcom set inside your own body. Eavesdrop on the gastro-gossip and insane inner arguments of your organs as you exercise.</p>
						<ul className="tags">
							<li>Comedy</li>
							<li>Episode 1 of 5</li>
							<li>9.1km</li>
						</ul>
					</div>				
				</div>

				<div className="story the-extraction" href="https://player.vimeo.com/video/508319762?autoplay=1" data-featherlight="iframe" data-featherlight-iframe-frameborder="0" data-featherlight-iframe-allow="autoplay; encrypted-media" data-featherlight-iframe-allowFullScreen="true" data-featherlight-iframe-style="display:block;border:none;height:100vh;width:100vw;">
					<a className="pov_btn">Watch POV demo</a>
					<div className="story-inner">
						<img src={theExtraction} className="story-title" alt="The Extraction"/>
						<ul>
							<li>Fast pace</li>
							<li>Super spy fans</li>
						</ul>
						<p>What starts as an extraction mission devolves into a secret agent's worst nightmare, when the crime lord he stole from always seems to be two steps ahead of him.</p>
						<ul className="tags">
							<li>Action</li>
							<li>Episode 1 of 2</li>
							<li>3.2km</li>
						</ul>
					</div>				
				</div>

			</div>

			<div className="panel quotes-section">
			
				<div className="quotes">
					<div className="quote">
						<h2>"You'll run further than you ever thought possible."</h2>
						<h3>GQ</h3>
					</div>
				</div>
			</div>

			<div className="panel logos-section all">
				<img src={alllogos} className="" alt=""/>
				<img src={social} alt=""/>
			</div>	
			<div className="panel appstores">
				<div className="download">
					<h2>Try It now</h2>
					
					<div className="links">
						<a href="https://apps.apple.com/sg/app/running-stories-workouts/id1528268691" className="ios"> <img src={app_store} alt="App Store"/> </a>
						<a href="https://play.google.com/store/apps/details?id=com.runningstories" className="android"><img src={play_store_active} alt="Play Store"/></a>
						
					</div>
				</div>

			</div>
    </div>
		</>
  )
}

export const query = graphql`
  query  {
    mdx(slug: {eq: "running-stories/"}) {
      frontmatter {
        title
        description
        type
        date(formatString: "MMMM DD, YYYY")
        image_alt
        image_credit_link
        image_credit_text
        feature_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`

export default BlogPost