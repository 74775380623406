import { useState, useEffect } from "react";


export default (element: any, rootMargin: string, threshold = 0.3) => {
    const [isVisible, setState] = useState(false);
    const [direction, setDirection] = useState<string>('down-enter');

    // const [previousY, setStateY] = useState(0);
    // const [previousRatio, setStateRatio] = useState(0);

    const thresholdArray = (steps: any) => Array(steps + 1)
        .fill(0)
        .map((_, index) => index / steps || 0)

    useEffect(() => {
        let previousY = 0;
        let previousRatio = 0;
        const observer = new IntersectionObserver(
            ([entry]) => {
                const currentY = entry.boundingClientRect.y
                const currentRatio = entry.intersectionRatio
                const isIntersecting = entry.isIntersecting

                // Scrolling down/up
                if (currentY < previousY) {
                    if (currentRatio > previousRatio && isIntersecting) {
                        setDirection('down-enter');
                    } else {
                        setDirection('down-leave');
                    }
                } else if (currentY > previousY && isIntersecting) {
                    if (currentRatio < previousRatio) {
                        setDirection('up-leave');
                    } else {
                        setDirection('up-enter');
                    }
                }

                previousY = currentY;
                previousRatio = currentRatio;
                // setStateY(currentY)
                // setStateRatio(currentRatio)

                // console.log('entry', entry)

                setState(entry.isIntersecting);

                // if (entry.isIntersecting) {
                    // setState(entry.isIntersecting);
                    // observer.unobserve(element.current);
                // }
            },
            {
              // root: null,
              rootMargin,
              threshold
            //   threshold: thresholdArray(20),
              // threshold: 1.0
            }
        );

        element.current && observer.observe(element.current);

        return () => {
            observer.unobserve(element.current);
        };
    }, []);

    return [isVisible, direction];
};
